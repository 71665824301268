
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { TypesUtilsService } from '../../@core/models';
import { Response } from '../../@core/models/query-models/_new-response-body.model';
import { environment } from '../../../environments/environment';

// const API_URL = "https://navitrack.fft.com.vn:8899/api/payment/getqr";
const API_URL = "https://api-wallet.fft.com.vn/api/payment/getqr";
const SYSTEM_PAYMENT_END_POINT = environment.api.paymentHost + '/admin/system/get-by-type?type=evcharge';

@Injectable({
  providedIn: 'root'
})
export class PaymentService {

  constructor(private http: HttpClient, private utils: TypesUtilsService) { }
  getQr(param?: any): Observable<Response> { 
    return this.http.post<Response>(API_URL, param, {headers: new HttpHeaders().set('Auth', 'False')});
  }

  getSystem(): Observable<Response> {
    return this.http.get<Response>(SYSTEM_PAYMENT_END_POINT, {headers: new HttpHeaders().set('Auth', 'False')});
  }
}

