import { NgModule } from '@angular/core';
import {
  NbButtonModule,
  NbCardModule, NbDatepickerModule,
  NbIconLibraries, NbIconModule, NbInputModule, NbLayoutModule, NbSelectModule, NbTimepickerModule,
  NbTooltipModule,
} from '@nebular/theme';
import { RouterModule } from '@angular/router';
import { PaymentComponent } from './payment.component';
import { routes } from '@nebular/auth';
import {TranslateModule} from "@ngx-translate/core";
import {ThemeModule} from "../../@theme/theme.module";
import {ReactiveFormsModule} from "@angular/forms";
import {SharedModule} from "../../shared/shared.module";
import {PaymentRoutingModule} from "./payment-routing.module";
import { NbMomentDateModule } from '@nebular/moment';

@NgModule({
  imports: [
    RouterModule.forChild(routes),
    NbLayoutModule,
    NbCardModule,
    TranslateModule,
    NbInputModule,
    ThemeModule,
    ReactiveFormsModule,
    NbTimepickerModule,
    NbButtonModule,
    SharedModule,
    NbSelectModule,
    NbDatepickerModule,
    PaymentRoutingModule,
    NbIconModule,
    NbTooltipModule,
    NbMomentDateModule
  ],
  declarations: [
    PaymentComponent
  ],
})
export class PaymentModule {
  constructor(iconsLibrary: NbIconLibraries) {
    iconsLibrary.registerFontPack('fa', { packClass: 'fa', iconClassPrefix: 'fa' });
   iconsLibrary.registerFontPack('far', { packClass: 'far', iconClassPrefix: 'fa' });
   iconsLibrary.registerFontPack('fas', { packClass: 'fas', iconClassPrefix: 'fa' });
  }
}
