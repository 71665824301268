<!-- <div class="container">
  <nb-card [ngStyle]="{ 'width': '660px', 'height': '600px' }" class="right-corner-card">
    <nb-card-header class="center-header">Thông Báo</nb-card-header>
    <nb-list nbInfiniteList [threshold]="500" [throttleTime]="100" (bottomThreshold)="loadNext()">
      <nb-list-item class="item-list" *ngFor="let item of listMessage ; trackBy: trackById"
        [ngStyle]="{'background-color': item.isRead == 0 ? 'rgb(174, 242, 151)' : 'white'}">
        <div class="container-fluid">
          <div class="row" (click)="handleClick(item)">
            <div class="message-header">
            <div class="container-fluid">
              <div class="row">
                <div class="col-2">
                </div>
                <div class="col-10">
                 <div class="row">
                  <div class="col-12">
                    <div class="container-fluid">
                      <div class="row">
                        <div class="col-6 title-container">
                          <div class="title" style="font-size: 13px; font-weight: 600; color: orange;">{{item.title}}</div>
                        </div>
                        <div class="col-6 created-at-container">
                          <div class="created-at" style="font-size: 13px; font-weight: 600; color: orange;">
                            {{item.createdAt}}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                 </div>
                </div>
              </div>
            </div>
            </div>
            <div class="message-content"
              [ngStyle]="{'background-color': item.isRead == 0 ? 'rgb(174, 242, 151)' : 'white'}">
              <div class="row">
                  <div class="display-message">
                    <div class="col-2 center-content">
                      <img src="../../../../assets/images/booking.jpg" *ngIf="item.notifyType === 'BOOKING'" alt="icon"
                        class="icon" style="width: 24px; height: 24px; margin-right: 8px; background-color: inherit;background-color: rgb(174, 242, 151);object-fit: cover;; ">
                        <img src="../../../../assets/images/top_up.jpg" *ngIf="item.notifyType === 'TOP_UP'" alt="icon"
                        class="icon" style="width: 24px; height: 24px; margin-right: 8px; background-color: inherit;background-color: rgb(174, 242, 151); ">
                    </div>
                    <div class="col-10 content-message">
                      {{item.content}}
                    </div>
                  </div>
              </div>
            </div>
          </div>
        </div>
      </nb-list-item>
    </nb-list>
  </nb-card>
</div> -->


<div ngbDropdown style="cursor: pointer" [placement]="'bottom-right'" class="kt-header__topbar-item kt-header__topbar-item--langs">
  <div ngbDropdownToggle class="kt-header__topbar-wrapper">
		<span class="kt-header__topbar-icon"
          [ngClass]="'kt-header__topbar-icon--brand'">
          <nb-action class="control-item" icon="bell-outline" style="justify-content: right;">
          </nb-action>
        </span>
  </div>
  <div ngbDropdownMenu aria-labelledby="dropdownBasic1" class="dropdown-menu dropdown-menu-fit dropdown-menu-right dropdown-menu-anim dropdown-menu-top-unround">
    <nb-card-header class="center-header">Thông Báo</nb-card-header>
    <nb-list nbInfiniteList [threshold]="500" [throttleTime]="100" (bottomThreshold)="loadNext()" style="width: 600px;">
      <nb-list-item class="item-list kt-header__topbar-icon--brand" *ngFor="let item of listMessage ; trackBy: trackById"
      [ngClass]="{'read-text': item.isRead == 1, 'unread-text': item.isRead == 0}">
        <!-- [ngStyle]="{'background-color': item.isRead == 0 ? 'rgb(174, 242, 151)' : 'white'}" -->
        <div class="container-fluid">
          <div class="row" (click)="handleClick(item)">
            <div class="message-header">
            <div class="container-fluid">
              <div class="row">
                <div class="col-2">
                </div>
                <div class="col-10">
                 <div class="row">
                  <div class="col-12">
                    <div class="container-fluid">
                      <div class="row">
                        <div class="col-6 title-container">
                          <div class="title" style="font-size: 13px; font-weight: 600; color: orange;">{{item.title}}</div>
                        </div>
                        <div class="col-6 created-at-container">
                          <div class="created-at" style="font-size: 13px; font-weight: 600; color: orange;">
                            {{item.createdAt}}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                 </div>
                </div>
              </div>
            </div>
            </div>
            <div class="message-content" [ngStyle]="{'background-color': item.isRead == 0 ? 'rgb(174, 242, 151)' : 'white'}">
              <div class="row">
                  <div class="display-message" [ngClass]="{'unread-text': item.isRead == 0}">
                    <div class="col-2 center-content">
                      <img src="../../../../assets/images/booking.jpg" *ngIf="item.notifyType === 'BOOKING'" alt="icon"
                        class="icon" style="width: 24px; height: 24px; margin-right: 8px; background-color: inherit;background-color: rgb(174, 242, 151) !important;object-fit: cover;">
                        <img src="../../../../assets/images/top_up.jpg" *ngIf="item.notifyType === 'TOP_UP'" alt="icon"
                        class="icon" style="width: 24px; height: 24px; margin-right: 8px; background-color: inherit;background-color: rgb(174, 242, 151) !important;object-fit: cover;">
                    </div>
                    <div class="col-10 content-message">
                      {{item.content}}
                    </div>
                  </div>
              </div>
            </div>
          </div>
        </div>
      </nb-list-item>

    </nb-list>
  </div>
</div>