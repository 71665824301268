import { Injectable } from "@angular/core";

@Injectable({
  providedIn: "root",
})
export class DataUtil {
    removeNullUndefined = obj => Object.entries(obj).filter(([_, v]) => v != null).reduce((acc, [k, v]) => ({ ...acc, [k]: v }), {});

    hexToRgba(hex: string, alpha: number): string {
      // Kiểm tra định dạng HEX (chấp nhận #RGB, #RRGGBB, hoặc không có #)
      if (!/^#?([A-Fa-f0-9]{3}|[A-Fa-f0-9]{6})$/.test(hex)) {
        throw new Error("Invalid HEX color format");
      }
    
      // Loại bỏ dấu # nếu có
      hex = hex.replace('#', '');
    
      // Xử lý trường hợp #RGB (rút gọn)
      if (hex.length === 3) {
        hex = hex.split('').map(char => char + char).join('');
      }
    
      // Chuyển đổi từng phần của HEX thành số thập phân
      const r = parseInt(hex.substring(0, 2), 16);
      const g = parseInt(hex.substring(2, 4), 16);
      const b = parseInt(hex.substring(4, 6), 16);
    
      // Trả về chuỗi RGBA
      return `rgba(${r}, ${g}, ${b}, ${alpha})`;
    }

    getContrastingTextColor(hex: string): string {
      // Kiểm tra định dạng HEX (hỗ trợ #RGB hoặc #RRGGBB)
      if (!/^#([A-Fa-f0-9]{6}|[A-Fa-f0-9]{3})$/.test(hex)) {
        throw new Error("Invalid HEX color format");
      }
    
      // Chuẩn hóa mã HEX (chuyển #RGB thành #RRGGBB)
      if (hex.length === 4) {
        hex = '#' + hex[1] + hex[1] + hex[2] + hex[2] + hex[3] + hex[3];
      }
    
      // Chuyển HEX sang RGB
      const r = parseInt(hex.substring(1, 3), 16) / 255;
      const g = parseInt(hex.substring(3, 5), 16) / 255;
      const b = parseInt(hex.substring(5, 7), 16) / 255;
    
      // Chuyển đổi RGB thành giá trị gamma
      const [rGamma, gGamma, bGamma] = [r, g, b].map((v) =>
        v <= 0.03928 ? v / 12.92 : Math.pow((v + 0.055) / 1.055, 2.4)
      );
    
      // Tính độ sáng tương đối
      const luminance = 0.2126 * rGamma + 0.7152 * gGamma + 0.0722 * bGamma;
    
      // Trả về màu đen hoặc trắng dựa trên độ sáng
      return luminance > 0.5 ? '#212529' : '#FFFFFF';
    }

    isNullOrEmpty(value:string):boolean{
      if(value == undefined || value == '' || value == null){
        return true;
      }
      return false;
    }
}
