// Angular
import { Injectable } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
// RxJS
import { Observable, BehaviorSubject, EMPTY, of } from 'rxjs';
import { tap, map, take, switchMap, filter } from 'rxjs/operators';
import { ResponseUtilsService } from './response-utils.service';
import { environment } from '../../../../environments/environment';
// import { debug } from 'util';

/**
 * More information there => https://medium.com/@MetonymyQT/angular-http-interceptors-what-are-they-and-how-to-use-them-52e060321088
 */
@Injectable()
export class InterceptService implements HttpInterceptor {
	private notifyGlobal: boolean = false;
	private toastConfig: any = {};
	private sessionStore: boolean = false;
	private localStore: boolean = false;
	constructor(private responseUtils: ResponseUtilsService) {
	}
	intercept(
		request: HttpRequest<any>,
		next: HttpHandler
	): Observable<HttpEvent<any>> {
		// tslint:disable-next-line:no-debugger
		// modify request
		// request = request.clone({
		// 	setHeaders: {
		// 		Authorization: ${localStorage.getItem('accessToken')}
		// 	}
		// }
		// });
		// console.log('----request----');
		// console.log(request);
		// console.log('--- end of request---');

		//#region check prefix send request		
		if (!this.checkHost(request.url)) {
			return EMPTY;
		}
		if (request.params.get('notifyGlobal') == 'true') {
			this.notifyGlobal = true;
			request = request.clone({
				params: request.params.delete('notifyGlobal')
			});
			if (request.params.get('toastConfig')) {
				this.toastConfig = request.params.get('toastConfig');
				request = request.clone({
					params: request.params.delete('toastConfig')
				})
			}
		}
		else {
			this.notifyGlobal = false;
		}
		if (request.params.get('localStore') == 'true') {
			this.localStore = true;
			request = request.clone({
				params: request.params.delete('localStore')
			});
		}
		else this.localStore = false;
		if (request.params.get('sessionStore') == 'true') {
			this.sessionStore = true;
			request = request.clone({
				params: request.params.delete('sessionStore')
			});
		}
		else this.sessionStore = false;
		let keySaveCache = request.urlWithParams;		
		// check cache 
		if (this.sessionStore === true && sessionStorage.getItem(keySaveCache)) {
			return of(new HttpResponse(
				JSON.parse(sessionStorage.getItem(keySaveCache))
			));
		}
		if (this.localStore === true && localStorage.getItem(keySaveCache)) {
			return of(new HttpResponse(
				JSON.parse(localStorage.getItem(keySaveCache))
			));
		}
		// check token 
		const userToken = localStorage.getItem(environment.projectConfig.authTokenKey);
		if (userToken == null && request.headers.get('Auth') !== "False") {
			return of(new HttpResponse(
				{}
			));
		}
		// check isLogin
		request = this.modifyHeader(request);
		//#endregion

		//#region  response
		return next.handle(request).pipe(
			tap(
				event => {
					if (event instanceof HttpResponse) {
						this.responseUtils.processSuccess(event, this.notifyGlobal, this.toastConfig);
						if (this.sessionStore === true) {
							sessionStorage.setItem(keySaveCache, JSON.stringify(event));
						}
						if (this.localStore === true) {
							localStorage.setItem(keySaveCache, JSON.stringify(event));
						}					
					}
				},
				error => {
					this.responseUtils.processError(error, this.notifyGlobal, this.toastConfig);
				}
			)
		);
		//#endregion
	}

	/**
	 * Check url request in list host allow config
	 * @param url Url request
	 */
	private checkHost(url: string) {
		let checkUrl = environment.hostAllow.findIndex(function (h) {
			if (url.match(h))
				return true;
		});
		if (checkUrl === -1) {
			return false;
		}
		return true;
	}

	/**
	 * Add header default
	 * @param request HttpRequest<any>
	 */
	private modifyHeader(request: HttpRequest<any>) {
		if (request.headers.get('Auth') == "False") {
			return request.clone({
				headers: request.headers
					// .set('AppID', environment.api.AppID)
					.set('Accept', 'application/json')
					// .set('Content-Type', 'application/json')
					.delete('Auth')
			});
		}
		else {
			const userToken = localStorage.getItem(environment.projectConfig.authTokenKey);
			let token = "";
			if (userToken != null) {
				token = userToken;
			}
			
			return request.clone({
				headers: request.headers
					// .set('Appid', environment.api.AppID)
					.set('Authorization', token)
					.set('Accept', 'application/json')
				// .set('Content-Type', 'application/json')
			});

		}
	}
}
