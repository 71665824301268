import { Injectable } from '@angular/core';
import { HttpHeaders, HttpParams, HttpClient, HttpResponse } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { Response } from '../models/query-models/_new-response-body.model';
import { UserSearchRequest } from '../models/request/user-search-request';
import { ResponseBody } from '../models/query-models/_response-body.model';
import { TypesUtilsService } from '../models/utils/types-utils.service';
import { BaseOptionAPI } from '../models/query-models/_base.option-api';
import { User } from '../models/entity/user';
import { map } from 'rxjs/operators';

const API_URL: string = environment.api.host + '/users';
const API_URL_SETTING: string = environment.api.host + '/settings';
const API_URL_NOTIFICATION: string = environment.api.host + '/settings/notification';

@Injectable({
	providedIn: 'root',
})
export class UserManageService {
	public hostApi;
	constructor(private http: HttpClient, private utils: TypesUtilsService) {
		this.hostApi = environment.api.host;
	}

	post(User: User, option?: BaseOptionAPI): Observable<Response> {
		return this.http.post<Response>(
			environment.api.host + '/users',
			User,
			{
				params: this.utils.processBassOptionApi(option),
			}
		);
	}

	put(User: User, option?: BaseOptionAPI) {
		return this.http.put(environment.api.host + '/users/' + User.id, User, {
			params: this.utils.processBassOptionApi(option),
		});
	}

	searchChildUsers(option: BaseOptionAPI) {
		return this.http.get<ResponseBody>(API_URL + '/search-child-users', {
			params: this.utils.processBassOptionApi(option),
		});
	}

	changePassWord(params,id, option?: BaseOptionAPI)
	{
		let formData = new FormData();
		Object.keys(params).forEach(x => {
		  formData.set(x, params[x]);
		});
		return this.http.put(environment.api.host + '/users/change-password/' + id, formData, {
			params: this.utils.processBassOptionApi(option),
		});
	}

	updateProfile(user: any, option?: BaseOptionAPI): Observable<Response>{
		// let formData = new FormData();
		// Object.keys(user).forEach((x) => {
		// 	formData.set(x, user[x]);
		// });
		return this.http.put<Response>(
			environment.api.host + '/users/profile',
			user,
			{
				params: this.utils.processBassOptionApi(option),
			}
		);
	}

	private walletBalanceSubject = new BehaviorSubject<any>({});
    walletBalanceObservable$ = this.walletBalanceSubject.asObservable();
	refreshWalletBalance(){
		return this.http.get<Response>(environment.api.host + '/users/wallet-balance').subscribe((data:any) => {
			this.walletBalanceSubject.next(data.data)
		});
	}

	getInfoParent(params, option?: BaseOptionAPI): Observable<ResponseBody> {
		return this.http.get<ResponseBody>(
			environment.api.host + '/dashboard',
			{ params: params }
		);
	}

	get(id: any | undefined) {
		return this.http.get(environment.api.host + '/users/' + id);
	}

	list(option?: BaseOptionAPI): Observable<ResponseBody> {
		return this.http.get<ResponseBody>(API_URL + '/search-users', {
			params: this.utils.processBassOptionApi(option),
		});
	}

	search(option?: UserSearchRequest, params?: any) {
		return this.http.post(API_URL + '/search-users', option, {params: params});
	}

	delete(id: number, option?: BaseOptionAPI): Observable<ResponseBody> {
		return this.http.delete<ResponseBody>(
			environment.api.host + '/users/' + id,
			{
				params: this.utils.processBassOptionApi(option),
			}
		);
	}

	resetPassword(params: any | undefined, option?: BaseOptionAPI) {
		return this.http.put(
			environment.api.host + '/users/reset-password-default/' + params.id,
			params,
			{
				params: this.utils.processBassOptionApi(option),
			}
		);
	}

	getKey(params: any | undefined): Observable<ResponseBody> {
		return this.http.get<ResponseBody>(
			environment.api.host + '/users/map-api-key',
			{
				params: params 
			}
		);
	}

	searchKey(filter: any | undefined) {
		return this.http.get(environment.api.host + '/users/map-api-key', {
			params: filter,
		});
	}

	changeActive(
		params: { id: number; active: number },
		option: BaseOptionAPI
	) {
		const formData = new FormData();
		formData.append('active', params.active.toString());
		return this.http.put(
			environment.api.host + '/users/map-api-key/change-status/' + params.id,
			formData,
			{
				params: this.utils.processBassOptionApi(option),
			}
		);
	}



	getUserUses(params: any | undefined): Observable<ResponseBody> {
		return this.http.get<ResponseBody>(
			environment.api.host + '/users/map-api-key-user',
			{ params },
		);
	}

	createUserUseApiKey(params: any, option?: BaseOptionAPI): Observable<ResponseBody> {
		const formData = new FormData();
		formData.append('userIds', params.userIds.toString());
		formData.append('apiKeyId', params.apiKeyId.toString());
		return this.http.post<ResponseBody>(
			environment.api.host + '/users/map-api-key-user',
			formData,
			{
				params: this.utils.processBassOptionApi(option),
			}
		);
	}

	deleteUserUseApiKey(id: number, option?: BaseOptionAPI): Observable<ResponseBody> {
		return this.http.delete<ResponseBody>(
			environment.api.host + '/users/map-api-key-user/' + id,
			{
				params: this.utils.processBassOptionApi(option),
			}
		);
	}

	deleteKey(id: number, option?: BaseOptionAPI): Observable<ResponseBody> {
		return this.http.delete<ResponseBody>(
			environment.api.host + '/users/map-api-key/' + id,
			{
				params: this.utils.processBassOptionApi(option),
			}
		);
	}

	// USER SETTINGS - NOTIFICATIONS
	putNotification(params: any, option?: BaseOptionAPI): Observable<ResponseBody> {
		const formData = new FormData();
		formData.append('userId', params.userId)
		formData.append('notification', JSON.stringify(params.notification));
		return this.http.post<ResponseBody>(
			API_URL_NOTIFICATION,
			formData,
			{ params:this.utils.processBassOptionApi(option), }
		);
	}

	listNotifications(userId): Observable<ResponseBody> 
	{
	  return this.http.get<ResponseBody>(API_URL_SETTING + "/" + userId);
	}

	importData(formData, option?: any): Observable<Blob | any> {
		const headers = new HttpHeaders({
		  'Accept': 'application/json, application/octet-stream'
		});
		return this.http.post(API_URL + '/import', formData, { headers: headers, observe: 'response', responseType: 'blob' as 'json' }).pipe(
		  map((response: HttpResponse<Blob | any>) => {
			const contentType = response.headers.get('Content-Type');
			if (contentType && contentType.includes('application/json')) {
			  // Convert Blob to JSON
			  return response.body.text().then((text: string) => JSON.parse(text));
			}
			return response.body;
		  })
		); 
	  }

	  changeBalance(model: any, option?: BaseOptionAPI): Observable<Response> {
		return this.http.post<Response>(API_URL + '/wallet-balance/change', model, {
		  params: this.utils.processBassOptionApi(option),
		});
	  }
}
