import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit, Optional } from '@angular/core';
import { NbDialogRef, NbDialogService } from '@nebular/theme';
import { NotificationPopUpService } from '../../../shared/services/notification.service';
import { HttpParams } from '@angular/common/http';
import { CurrentUserService } from '../../../shared/services/current-user.service';
import { BookingDetailComponent } from '../../../pages/report/booking-history/booking-detail/booking-detail.component';
import { Subject } from 'rxjs';

interface LanguageFlag {
  lang: string;
  name: string;
  flag: string;
  active?: boolean;
}

@Component({
  selector: 'ngx-popup-notification',
  templateUrl: './popup-notification.component.html',
  styleUrls: ['./popup-notification.component.scss'],
  changeDetection: ChangeDetectionStrategy.Default 
})
export class PopupNotificationComponent implements OnInit {
  @Input() listMessage: any = [];

  @Input() message: string;

  @Input() pageToLoadNext = 0;
  disableLoadNext: boolean = false;
  @Input() idUser : any;

  selectedDate: string | null = null;
  notiService: NotificationPopUpService;
  currentUserService: CurrentUserService;
  private unsubscribe: Subject<any>;

  @Input() iconType: '' | 'brand';
  language: LanguageFlag;

  constructor(@Optional() protected ref: NbDialogRef<PopupNotificationComponent>,
    private _notiService: NotificationPopUpService,
    private cdr: ChangeDetectorRef,
    private _currentUserService: CurrentUserService,
    private  dialogService: NbDialogService,
  ) {
    this.notiService = _notiService;
    this.currentUserService = _currentUserService
    this.unsubscribe = new Subject();
  }


  ngOnInit(): void {
  
  }


  handleClick(item) {
    var dataCharge = JSON.parse(item.data)
    var bookingId = dataCharge.bookingId;
    this.detail(bookingId)
    if(item.isRead == 0) {
      this.updateStatus(item)
    }
  }

  detail(bookingId: number) {
    const ref = this.dialogService.open(BookingDetailComponent, {
      autoFocus: false,
      context: {
        bookingId: bookingId
      },
    });
    ref.onClose.takeUntil(this.unsubscribe).subscribe();
  }

  loadNext() {
    if(!this.idUser) {
      this.getUserId();
      return;
    }
    const options = {
      userId: this.idUser
    };

    const params = new HttpParams()
      .set('page', this.pageToLoadNext)
      .set('size', '10')
      .set('sort', 'createdAt.desc');
    this.notiService.search(options, params).takeUntil(this.unsubscribe).subscribe((res) => {
      if(res.errorCode === '200'){
        if (this.pageToLoadNext + 1 < res.data.totalPages) {
          this.pageToLoadNext++;
          res.data.content.forEach(mess => {
            this.listMessage.push(mess)
          });
        } else {
          this.disableLoadNext = true;
        }
      }
    }
    )
  }

  async getUserId() {
    await this._currentUserService
        .getCurrentUser()
        .then((data: any) => {
            this.idUser = data.id;
            this.loadNext();
        });
  }

  updateStatus(item) {
    if (item.isRead == 1) {
      return
    } else {
      const index = this.listMessage.findIndex(msg => msg.id === item.id);
      this.listMessage[index].isRead = 1;
      this.notiService.updateStatus(item.id).takeUntil(this.unsubscribe).subscribe();
    }
  }

  trackById(index: number, item: any): number {
    return item.isRead; // Trả về id hoặc thuộc tính duy nhất khác
  }
}
