import {PaymentService} from '../../shared/services/payment.service';
import {ActivatedRoute, Router} from '@angular/router';
import {ChangeDetectorRef, Component, EventEmitter, ViewChild} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {finalize, takeUntil} from "rxjs/operators";
import {Subject} from "rxjs";
import {NbDateService, NbStepChangeEvent, NbStepperComponent} from "@nebular/theme";
import * as moment from 'moment'
import {formatDate} from '@angular/common';
import {TranslateService} from '@ngx-translate/core';
import {BookingService} from '../../shared/services/booking.service';
import {MapUserService} from '../../shared/services/map-user.service';
import {TrackingUtil} from '../../shared/utils/tracking-util';
import {DomSanitizer} from '@angular/platform-browser';
import { MapConfigModel } from '../../shared/model/map-config';
import { MapConfig } from '../../shared/config/map.config';

@Component({
  selector: "ngx-payment",
  templateUrl: "./payment.component.html",
  styleUrls: ["./payment.component.scss"],
})
export class PaymentComponent {
  saveForm: FormGroup;
  idParentUserEdit: any;
  deviceId: any;
  userIdSelected: any;
  connectors: any[] = [];
  min: Date;
  deviceImei: any;
  qrLink: any = this.sanitizer.bypassSecurityTrustResourceUrl(
    "https://vietqr.vn/qr-generated?token=ZDE5Y2U5OWMtODc3MS00NDdmLTk4YTctNTVmZWI4MjNlOTc4"
  );

  parrentEmiter: EventEmitter<boolean | { id: number; path: string }> =
    new EventEmitter<boolean | { id: number; path: string }>();
  qrCodePage = false;
  @ViewChild("stepper") stepperComponent: NbStepperComponent;
  bookingDetail: any;
  connectorId = 0;
  listPeriodTime: any[] = [];
  frameTimeIndex = 0;
  resultTitle;
  resultMessage;
  connectorNumber;
  deviceInfo:any;
  connectorInfo:any;
  chargeTime = 0;
  cost : any = 0;
  timeNow = formatDate(new Date(), "dd/MM/yyyy", "en-US");
  private configMap: MapConfigModel;
  private unsubscribe: Subject<any> = new Subject();

  constructor(
    private paymentService: PaymentService,
    private route: ActivatedRoute,
    private fb: FormBuilder,
    private cdr: ChangeDetectorRef,
    public mapService: MapUserService,
    protected dateService: NbDateService<Date>,
    private bookingService: BookingService,
    private translateService: TranslateService,
    private trackingUtilUtil: TrackingUtil,
    private sanitizer: DomSanitizer,
    private router: Router
  ) {
    this.configMap = new MapConfig().configs();
    this.min = this.dateService.addDay(this.dateService.today(), 0);
    this.saveForm = this.fb.group({
      hour: [2],
      date: [{ value: new Date(), disabled: true }],
      connectorId: [0, [Validators.required]],
      startTime: [moment(), [Validators.required]],
      endTime: [this.addHours(moment(), 2), [Validators.required]],
      timeFrameId: [0, [Validators.required]],
    });
    this.calculateChargeTime();
  }

  ngOnInit(): void {
    this.route.queryParams.subscribe((params) => {
      this.deviceImei = params["imei"];
      this.connectorNumber = params["connectorNumber"];
      if (!this.deviceImei || !this.connectorNumber)
        this.router.navigate(["/auth/login"]);
      this.getEvChargeDetail(this.deviceImei);
    });
  }

  ngOnDestroy() {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  onSubmit() {
    if (this.saveForm.invalid) {
      return;
    }
    const form = this.saveForm.getRawValue();
    let amount = Number(this.deviceInfo.electricityPrice * this.connectorInfo.connectorInfo.maximumOutputPower * (this.chargeTime / 60));
    const params = {
      Imei: this.deviceImei,
      Wallet: "VietQR",
      Position: form.connectorId,
      Content: "EV" + this.deviceImei,
      Duration: this.chargeTime,
      Amount: Math.round(amount),
    } as any;
    this.qrCodePage = true;
    this.paymentService
      .getQr(params)
      .takeUntil(this.unsubscribe)
      .subscribe((result: any) => {
        if (result.errorCode === "200" && result?.data?.qrLink != null) {
          this.qrLink = this.sanitizer.bypassSecurityTrustResourceUrl(
            result?.data?.qrLink
          );
          this.cdr.detectChanges();
          this.qrCodePage = true;
        } else {
          this.qrCodePage = false;
        }
      });
  }

  getTimeDiff(lastStatusTime: any, lastMeterTime: any) {
    const difference = moment(lastStatusTime).diff(moment(lastMeterTime));
    const duration = moment.duration(difference);
    const minutes = Math.round(duration.asMinutes() % 60);
    const hours = Math.floor(duration.asMinutes() / 60);
    return `${hours}h ${minutes}m`;
  }

  chooseConnector(connector: any) {
    this.connectorInfo = connector;
    this.connectorId = connector.connectorId;
    this.frameTimeIndex = 0;
    this.saveForm.patchValue({
      connectorId: connector?.connectorInfo?.connectorNumber,
    });
    if (connector.status !== Number(this.configMap.status.Available.key)) return;
  }

  confirm(id) {
    this.bookingService
      .confirm(id, { notifyGlobal: true })
      .takeUntil(this.unsubscribe)
      .subscribe((result: any) => {
        if (result.errorCode === "200") {
          this.resultTitle = this.translateService.instant(
            "MANAGE.BOOKING.SUCCESS"
          );
          this.resultMessage = undefined;
        }
      });
  }

  resetBooking() {
    this.frameTimeIndex = 0;
    this.connectorId = 0;
    this.listPeriodTime = [];
    this.bookingDetail = undefined;
    this.resultTitle = this.translateService.instant("MANAGE.BOOKING.FAIL");
    this.saveForm.patchValue({ startTime: null, endTime: null });
    this.cdr.detectChanges();
  }

  handleStepChange(e: NbStepChangeEvent): void {
    if (e.index == 0) {
      this.resetBooking();
    }
  }

  handleTime($event, isStartTime: boolean) {
    if ($event?.save) {
      if (isStartTime) {
        this.saveForm.patchValue({
          startTime: $event.time
        });
      } else {
        this.saveForm.patchValue({
          endTime: $event.time
        });
      }
      this.calculateChargeTime();
    }
  }

  getEvChargeDetail(imei: string) {
    this.mapService
      .getEvDetailByImei(imei)
      .pipe(
        takeUntil(this.unsubscribe),
        finalize(() => {
          this.cdr.markForCheck();
        })
      )
      .subscribe((result: any) => {
        if (result.errorCode === "200") {
          this.deviceInfo = result.data;
          this.deviceInfo.electricityPriceFormat = new Intl.NumberFormat('en-US').format(this.deviceInfo.electricityPrice);
          this.connectors = this.deviceInfo.connectorStatusInfos.map((e) =>
            this.trackingUtilUtil.processStatusConnect(e)
          );
          this.connectors.forEach((connector) => {
            if (
              connector.statusType.text.includes("Maintenance")
            ) {
              connector.disable = true;
            } else if (
              connector?.connectorInfo?.connectorNumber == this.connectorNumber
            ) {
              this.chooseConnector(connector);
            } else {
              connector.disable = false;
            }
          });
          this.calculateCost();
        }
      });
  }

  getRound(getRound: any) {
    if(getRound){
      return getRound.toFixed(2);
    }
    return 0;
  }

  addHours(date, hours) {
    date.add(hours, 'hours');
    return date;
  }

  goToChooseEvcharge(){
    this.qrCodePage = false;
  }

  calculateChargeTime(){
    this.chargeTime = Math.round((this.saveForm.value.endTime.toDate().getTime() - this.saveForm.value.startTime.toDate().getTime()) / 60000);
    this.calculateCost();
  }

  calculateCost(){
    this.cost = Number(this.deviceInfo?.electricityPrice * this.connectorInfo?.connectorInfo?.maximumOutputPower * (this.chargeTime / 60));
    this.cost = new Intl.NumberFormat('en-US').format(this.cost);
  }
}
